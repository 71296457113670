import React, { useEffect, useState } from 'react';
import { Typography, Avatar, Grid, Card, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';

const apiUrl = process.env.REACT_APP_API_URL;
const HoverTableRow = styled(TableRow)(({ theme }) => ({    '&:hover': {      backgroundColor: theme.palette.action.hover,    },  }));

// Function to fetch public user profile data by ID
const fetchPublicUserProfile = async (userId) => {
    try {
      const response = await fetch(`${apiUrl}/api/public-user-profile/${userId}/`).catch(() => null);
      if (response && response.status === 404) {    return { error: 'No such creator found' }; }
      if (!response || !response.ok) {
        return { error: `An unexpected error occurred ${response ? response.status : 'no response'})` };
      }
      return await response.json();
    } catch (error) {
      console.warn('Caught fetch error:', error);
      return { error: 'Failed to fetch creator information. Please try again later.' };
    }
  };

const CreatorPage = () => {
  const { creatorId } = useParams();  // Assuming creatorId comes from route
  const [creatorData, setCreatorData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      console.debug("Fetching user data...");
      const data = await fetchPublicUserProfile(creatorId);
      if (data.error || data.role !== 'creator') { setError('No such creator found');
      } else { setCreatorData(data); }
    }; loadData();
  }, [creatorId]);

  if (error) {
    return ( <Container sx={{ paddingTop: 15, paddingBottom: 4 }}><Typography variant="h4" color="error" textAlign="center" sx={{ mt: 5 }}> {error}</Typography></Container> );
  }
  if (!creatorData) {
    return ( <Container sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}> <Typography variant="h5">Loading...</Typography> </Container> );
  }

  // Prepare social media data for table
  const socialMediaData = [
    {
      platform: 'YouTube',
      followers: creatorData.youtube_subscribers,
      keyMetric: `${creatorData.youtube_video_count} videos, ${creatorData.youtube_views} views`,
      channelLink: creatorData.youtube_channelId ? `https://www.youtube.com/channel/${creatorData.youtube_channelId}` : null
    },
    {
      platform: 'Instagram',
      followers: creatorData.instagram_followers,
      keyMetric: 'Followers: ' + creatorData.instagram_followers,
      channelLink: creatorData.instagram_channelId ? `https://instagram.com/${creatorData.instagram_channelId}` : null
    },
    {
      platform: 'Twitter',
      followers: creatorData.twitter_followers,
      keyMetric: 'Followers: ' + creatorData.twitter_followers,
      channelLink: creatorData.twitter_channelId ? `https://twitter.com/${creatorData.twitter_channelId}` : null
    },
    {
      platform: 'TikTok',
      followers: creatorData.tiktok_followers,
      keyMetric: 'Followers: ' + creatorData.tiktok_followers,
      channelLink: null  // Assuming TikTok links aren’t available directly
    }
  ];

      return (
        <Container sx={{ paddingTop: 15, paddingBottom: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Avatar
                alt={creatorData.username}
                src={creatorData.profile_picture || 'https://via.placeholder.com/150'}
                sx={{ width: 150, height: 150, marginBottom: 2 }}
              />
              <Typography variant="h4">{creatorData.username}</Typography>
              <Typography variant="subtitle1">Role: {creatorData.role}</Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary', marginTop: 1 }}>Joined: {new Date(creatorData.date_joined).toLocaleString('default', { month: 'long', year: 'numeric' })}</Typography>
              <Typography variant="body1" sx={{ marginTop: 2 }}>{creatorData.bio || 'No bio available.'}</Typography>
            </Grid>
    
            <Grid item xs={12} md={8}>
              <Card sx={{ padding: 2, marginBottom: 3 }}>
                <Typography variant="h6">Website</Typography>
                <Typography variant="body2">
                  {creatorData.my_website ? ( <a href={creatorData.my_website} target="_blank" rel="noopener noreferrer"> {creatorData.my_website} </a> ) : 'No website provided.'}
                </Typography>
              </Card>
    
              <Card sx={{ padding: 2 }}>
                <Typography variant="h6" sx={{ marginBottom: 2 }}>Social Media Statistics</Typography>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow><TableCell>Platform</TableCell><TableCell>Followers</TableCell><TableCell>Metrics</TableCell><TableCell>Link</TableCell></TableRow>
                    </TableHead>
                    <TableBody>
                      {socialMediaData.map((social, index) => (
                        <HoverTableRow key={index}>
                          <TableCell>{social.platform}</TableCell>
                          <TableCell>{social.followers || 0}</TableCell>
                          <TableCell>{social.keyMetric}</TableCell>
                          <TableCell>{social.channelLink ? ( <a href={social.channelLink} target="_blank" rel="noopener noreferrer"> View Channel </a> ) : 'N/A'}</TableCell>
                        </HoverTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </Grid>
          </Grid>
        </Container>
      );
    };
    
    export default CreatorPage;
    