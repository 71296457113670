import React, { useEffect, useState } from 'react';
import { Typography, Avatar, Grid, Card, Container } from '@mui/material';
import { useParams } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL;

// Function to fetch public user profile data by ID
const fetchPublicUserProfile = async (userId) => {
  try {
    const response = await fetch(`${apiUrl}/api/public-user-profile/${userId}/`);
    if (response.status === 404) {      return { error: 'No such brand found' };    }
    if (!response.ok) {      return { error: `An unexpected error occurred (status: ${response.status})` };    }
    return await response.json();
  } catch (error) {
    console.warn('Caught fetch error:', error);
    return { error: 'Failed to fetch brand information. Please try again later.' };
  }
};

const BrandPage = () => {
  const { brandId } = useParams();  // Assuming brandId comes from route
  const [brandData, setBrandData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      const data = await fetchPublicUserProfile(brandId);
      if (data.error || data.role !== 'brand') { setError('No such brand found.');
      } else { setBrandData(data); }
    }; loadData();
  }, [brandId]);

  if (error) {return ( <Container sx={{ paddingTop: 15, paddingBottom: 4 }}><Typography variant="h4" color="error" textAlign="center" sx={{ mt: 5 }}> {error} </Typography></Container> );}
  if (!brandData) { return (<Container sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Typography variant="h5">Loading...</Typography></Container>); }

  return (
    <Container sx={{ paddingTop: 15, paddingBottom: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Avatar
            alt={brandData.username}
            src={brandData.profile_picture || 'https://via.placeholder.com/150'}
            sx={{ width: 150, height: 150, marginBottom: 2 }}
          />
          <Typography variant="h4">{brandData.username}</Typography>
          <Typography variant="subtitle1">Role: {brandData.role}</Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary', marginTop: 1 }}>Joined: {new Date(brandData.date_joined).toLocaleString('default', { month: 'long', year: 'numeric' })}</Typography>
          <Typography variant="body1" sx={{ marginTop: 2 }}>{brandData.bio || 'No bio available.'}</Typography>
        </Grid>

        <Grid item xs={12} md={8}>
          <Card sx={{ padding: 2, marginBottom: 3 }}>
            <Typography variant="h6">Website</Typography>
            <Typography variant="body2">
              {brandData.my_website ? (<a href={brandData.my_website} target="_blank" rel="noopener noreferrer">{brandData.my_website}</a>) : 'No website provided.'}
            </Typography>
          </Card>
          <Card sx={{ padding: 2 }}>
            <Typography variant="h6">Additional Information</Typography>
            <Typography variant="body2">Here you can include other brand-specific details as needed.</Typography>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default BrandPage;
