import React, { useState, useEffect, useCallback } from 'react';
import { auth, storage } from '../service_components/firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { updateProfile, updatePassword } from 'firebase/auth';
import Box from '@mui/material/Box';
import ProfilePicture from './ProfilePicture';
import TextField from '@mui/material/TextField';
import { Typography, Button } from '@mui/material';
import { Divider, Grid } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ThemeProvider } from '@mui/material/styles';
import AIScraper from './AIScraper';
import MetricsVisualizer from './MetricsVisualizer';
const apiUrl = process.env.REACT_APP_API_URL;

function Settings({ onProfilePictureUpdate, displayName, setDisplayName, userRole, theme}) {
    const [newPassword, setNewPassword] = useState('');
    const [repeatNewPassword, setRepeatNewPassword] = useState('');
    const [bio, setBio] = useState('');
    const [idToken, setIdToken] = useState("");
    const [scraperData, setScraperData] = useState(null);

    const handleScraperData = (data) => { setScraperData(data); };

    const fetchToken = useCallback(async () => {
        if (auth.currentUser) { const token = await auth.currentUser.getIdToken(true); setIdToken(token); } else { console.error("Failed to fetch user data"); }}, []);
    useEffect(() => { fetchToken(); }, [fetchToken]);

    const fetchUserProfile = useCallback(async () => {
        try {
            const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${idToken}` };
            const profileResponse = await fetch(`${apiUrl}/api/user-profile/`, { method: 'GET', headers: headers });
            const profileData = await profileResponse.json();
            if (profileResponse.ok) { setBio(profileData.bio || ""); } else { console.error('Failed to fetch profile data:', profileData.message); }
        } catch (error) { console.error('Error getting profile:', error); }
    }, [idToken]);
    useEffect(() => { if (idToken) { fetchUserProfile(); }}, [idToken, fetchUserProfile]);

    const handleProfileSubmitToFirebase = async (e) => {
        e.preventDefault();
        if (auth.currentUser) {
            await updateProfile(auth.currentUser, { displayName: displayName });
            const newData = { bio: bio };
            await updateProfileData(newData);
            alert("Profile updated successfully.");
        }
    };

    const updateProfileData = useCallback(async (data) => {
        try {
            const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${idToken}` };
            const response = await fetch(`${apiUrl}/api/update-profile/`, { method: 'POST', headers: headers, body: JSON.stringify(data) });
            const responseData = await response.json();
            if (!response.ok) { console.error('Failed to update profile:', responseData.message); }
        } catch (error) { console.error('Error updating profile:', error); }
    }, [idToken]);

    const handlePasswordSubmit = async (e) => {
        e.preventDefault();
        if (newPassword !== repeatNewPassword) { alert("Passwords do not match!"); return; }
        if (auth.currentUser && newPassword) {
            try {
                await updatePassword(auth.currentUser, newPassword);
                alert("Password updated successfully.");
                setNewPassword(''); 
                setRepeatNewPassword('');
            } catch (error) {
                console.error(error);
                alert("Failed to update password. Make sure you're recently logged in");
            }
        }
    };

    const handleUpload = async (e) => {
        const file = e.target.files[0];
        if (!file) { alert("No file selected!"); return; }

        const imageRef = ref(storage, `profilePictures/${auth.currentUser.uid}`);
        try {
            await uploadBytes(imageRef, file);
            const url = await getDownloadURL(imageRef);
            await updateProfile(auth.currentUser, { photoURL: url });

            // Send URL to backend
            await fetch(`${apiUrl}/api/update-profile/`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${await auth.currentUser.getIdToken()}` },
                body: JSON.stringify({ profile_picture_url: url }) });

            alert("Profile picture updated successfully.");
            onProfilePictureUpdate(url); 
        } catch (error) {
            console.error("Error uploading image: ", error);
            alert("Failed to upload image. Please try again later.");
        }
    };

    return (
        <ThemeProvider theme={theme}>
        <Grid container sx={{ width: "100%"}}>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%", height: "60px", mx: { xs: 1, sm: 4 }, mt: 4 }}>
                <Typography variant="h5" sx={{ fontWeight: 600 }}> Settings </Typography>
                <Typography variant="subtitle2"> <span style={{ color: theme.palette.primary.main }}>Profile</span> {'>'} Settings </Typography>
                
            </Box>
                        
            {/* public info */}
            <Grid item xs={12} sx={{display: 'flex', flexDirection: 'column', boxShadow: 1, borderRadius: '4px', mx: { xs: 1, sm: 4 }, my: 2, p: 2, backgroundColor: theme.palette.mode === 'light' ? "#fff" : "#233043" }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 600 }}> Public info </Typography>
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, width: "100%" }}>
                    <Box sx={{ width: { md: '30%', xs: '100%' }, height: "280px", mr: 2, p: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <input type="file" accept="image/*" style={{ display: 'none' }} id="avatar-upload" onChange={handleUpload} />
                        <label htmlFor="avatar-upload" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}> <ProfilePicture size={128} /> </label>
                        <Typography variant="caption" sx={{ textAlign: 'center', maxWidth: '80%', mx: 'auto', mt: 2 }}>Click to change your avatar. The best image will be 256x256 .png or .jpg</Typography>
                    </Box>
                    <Box sx={{ width: { md: '70%', xs: '100%' }, height: "280px", p: 1 }}>
                        <TextField label="Username" variant="outlined" fullWidth margin="dense" InputProps={{ style: { fontSize: '0.875rem' } }} InputLabelProps={{ style: { fontSize: '0.875rem' }}} 
                            value={displayName} onChange={(e) => setDisplayName(e.target.value)}/>
                        <TextField label="Short bio" variant="outlined" fullWidth margin="dense" multiline rows={4} InputProps={{ style: { fontSize: '0.875rem' } }} InputLabelProps={{ style: { fontSize: '0.875rem' }}} 
                            value={bio} onChange={(e) => setBio(e.target.value)} placeholder="Please fill in your bio"/>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}><Button variant="contained" onClick={handleProfileSubmitToFirebase} sx={{ mt: 2 }}> Save Profile </Button></Box>
                    </Box>
                </Box>
            </Grid>

            {/* private info */}
            <Grid item xs={12} sx={{display: 'flex', flexDirection: 'column', boxShadow: 1, borderRadius: '4px', mx: { xs: 1, sm: 4 }, my: 2, p: 2, backgroundColor: theme.palette.mode === 'light' ? "#fff" : "#233043" }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 600 }}> Login info </Typography>
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, width: "100%" }}>
                    <Box sx={{ width: { md: '70%', xs: '100%' }, height: "280px", mr: 2, p: 1 }}>
                        <Typography variant="body2">  Your role: {userRole.charAt(0).toUpperCase() + userRole.slice(1)}</Typography>
                        <Typography variant="body2">  Your Abunda user ID: <span style={{ fontFamily: 'monospace' }}>{auth.currentUser.uid}</span></Typography>

                        <Divider sx={{ my: 2, opacity: 0.2, borderColor: 'grey.500' }} />

                        <TextField label="email" variant="outlined" fullWidth margin="dense" InputLabelProps={{ style: { fontSize: '0.875rem' }}} 
                            InputProps={{ style: { fontSize: '0.875rem' }, endAdornment: auth.currentUser.emailVerified ? <CheckCircleIcon color="success" /> : null}} 
                            value={auth.currentUser.email} />
                        <Grid container spacing={1}> 
                            <Grid item xs={6} > <TextField label="New password" variant="outlined" fullWidth margin="dense" InputProps={{ style: { fontSize: '0.875rem' } }} InputLabelProps={{ style: { fontSize: '0.875rem' }}} 
                                type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)}/></Grid>
                            <Grid item xs={6} > <TextField label="Repeat New Password" variant="outlined" fullWidth margin="dense" InputProps={{ style: { fontSize: '0.875rem' } }} InputLabelProps={{ style: { fontSize: '0.875rem' }}} 
                                type="password" value={repeatNewPassword} onChange={(e) => setRepeatNewPassword(e.target.value)}/> </Grid>
                        </Grid>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}> <Button variant="contained" onClick={handlePasswordSubmit} sx={{ mt: 2 }} > Update Password </Button> </Box>
                    </Box>
                    <Box sx={{ width: { md: '30%', xs: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'top' }, height: "280px", p: 1 }}>
                        <Typography variant="body2" sx={{ mt: 2 }} > Member for </Typography>
                        <Typography variant="body2" sx={{ fontWeight: 600 }}> { Math.floor( (new Date(auth.currentUser.metadata.lastSignInTime).getTime() - new Date(auth.currentUser.metadata.creationTime).getTime()) / (1000 * 3600 * 24) ) } days </Typography>
                        <Typography variant="body2" sx={{ mt: 2 }} > Account created </Typography>
                        <Typography variant="body2"> {auth.currentUser.metadata.creationTime}</Typography>
                        <Typography variant="body2" sx={{ mt: 2 }}> Your last login </Typography>
                        <Typography variant="body2"> {auth.currentUser.metadata.lastSignInTime}</Typography>
                    </Box>
                </Box>
            </Grid>

            {/* ai assistant */}
            <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', boxShadow: 1, borderRadius: '4px', mx: { xs: 1, sm: 4 }, my: 2, p: 2, backgroundColor: theme.palette.mode === 'light' ? "#fff" : "#233043" }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 600 }}> Creator's Social Profile Analysis </Typography>
                <AIScraper onData={handleScraperData} />
            </Grid>

            {/* Metrics Visualization container - only shown if scraperData is present */}
            {scraperData && (
                <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', boxShadow: 1, borderRadius: '4px', mx: { xs: 1, sm: 4 }, my: 2, p: 2, backgroundColor: theme.palette.mode === 'light' ? "#fff" : "#233043" }}>
                    <MetricsVisualizer data={scraperData} />
                </Grid>
            )}
        </Grid>
        </ThemeProvider>
    );
}

export default Settings;