import React from 'react';
import { Typography, List, ListItem, Link } from '@mui/material';

const listItemStyle = { paddingTop: 0, paddingBottom: 0, paddingLeft: '16px' };
const listItemStyle2 = { paddingTop: 0, paddingBottom: 0, paddingLeft: '32px' };
const listItemStyle3 = { paddingTop: 0, paddingBottom: 0, paddingLeft: '48px' };

const Documentation = () => {
  return (
    <div style={{ padding: '140px' }}>
      <Typography variant="h4" gutterBottom> Abunda Project Documentation </Typography>

      {/* Table of Contents */}
      <Typography variant="h6" gutterBottom> Table of Contents </Typography>
      <List>
        <ListItem sx={listItemStyle}><Link href="#section-1">1. Introduction</Link></ListItem>
        <ListItem sx={listItemStyle}><Link href="#section-2">2. Architecture Overview</Link></ListItem>
        <ListItem sx={listItemStyle}><Link href="#section-3">3. Components</Link></ListItem>
        <ListItem sx={listItemStyle}><Link href="#section-4">4. Deployment and Configuration</Link></ListItem>
        <ListItem sx={listItemStyle}><Link href="#section-5">5. Security Measures</Link></ListItem>
        <ListItem sx={listItemStyle}><Link href="#section-6">6. Integration Overview</Link></ListItem>
      </List>

      <section id="section-1" style={{ marginTop: '40px' }}>
        <Typography variant="h5" gutterBottom> 1. Introduction </Typography>
        <Typography>
          Abunda is a web-based marketing platform designed to connect brands and content creators. It allows both brands and creators to create profiles, collaborate through missions, and manage project interactions efficiently. The system integrates external services like Firebase for authentication and Stripe for payment management.
        </Typography>
      </section>

      <section id="section-2" style={{ marginTop: '40px' }}>
        <Typography variant="h5" gutterBottom> 2. Architecture Overview </Typography>
        <Typography variant="h6" style={{ marginTop: '20px' }}>2.1 System Overview</Typography>
        <Typography>
            The Abunda platform follows a client-server architecture, with a <strong>Django backend</strong> and a <strong>React frontend</strong>. The system leverages <strong>Firebase</strong> for authentication, and <strong>Stripe</strong> for payment processing. The backend integrates with external APIs to handle user data and subscriptions.
        </Typography>

        <Typography variant="h6" style={{ marginTop: '20px' }}>2.2 Backend (Django)</Typography>
        <List>
            <ListItem sx={listItemStyle}><Typography><strong>Framework:</strong> Django</Typography></ListItem>
            <ListItem sx={listItemStyle}><Typography><strong>Language:</strong> Python</Typography></ListItem>
            <ListItem sx={listItemStyle}><Typography><strong>Database:</strong> SQLite</Typography></ListItem>
        </List>
        <Typography>
        The backend handles core business logic, API requests, user subscriptions, profile management, and mission management. The backend is served through Gunicorn as the WSGI server, which handles Python application requests and forwards responses to Nginx.Custom Firebase middleware validates user authentication using Firebase tokens, while Stripe manages subscriptions and payments.
        </Typography>

        <Typography variant="h6" style={{ marginTop: '20px' }}>2.3 Frontend (React)</Typography>
        <List>
            <ListItem sx={listItemStyle}><Typography><strong>Framework:</strong> React 18</Typography></ListItem>
            <ListItem sx={listItemStyle}><Typography><strong>State Management:</strong> Component-level state using React hooks (<code>useState</code>, <code>useEffect</code>, <code>useCallback</code>)</Typography></ListItem>
            <ListItem sx={listItemStyle}><Typography><strong>UI Library:</strong> Material-UI 5</Typography></ListItem>
            <ListItem sx={listItemStyle}><Typography><strong>Routing:</strong> React Router (version 6)</Typography></ListItem>
            <ListItem sx={listItemStyle}><Typography><strong>Analytics:</strong> Google Analytics 4 (ReactGA)</Typography></ListItem>
            <ListItem sx={listItemStyle}><Typography><strong>Environments:</strong> Development: .env.local with API URL pointing to localhost and Production: .env.production with API URL pointing to https://abunda.pt</Typography></ListItem>
        </List>
        <Typography>
            The frontend dynamically renders user interfaces and manages the application state using hooks. It connects to the backend through REST APIs to retrieve subscription details, user data, and social media metrics.
        </Typography>

        <Typography variant="h6" style={{ marginTop: '20px' }}>2.4 Key Backend Files</Typography>
        <List>
            <ListItem sx={listItemStyle}><Typography><strong>wsgi.py:</strong> Sets up the WSGI application, used for deploying Django with traditional web servers.</Typography></ListItem>
            <ListItem sx={listItemStyle}><Typography><strong>urls.py:</strong> Maps URLs to views, acting as the main routing configuration for the backend.</Typography></ListItem>
            <ListItem sx={listItemStyle}><Typography><strong>middleware.py:</strong> Contains custom middleware, including request handling and user authentication integration via Firebase tokens.</Typography></ListItem>
            <ListItem sx={listItemStyle}><Typography><strong>settings_dev.py / settings_prod.py:</strong> Environment-specific configurations for development and production setups.</Typography></ListItem>
        </List>

        <Typography variant="h6" style={{ marginTop: '20px' }}>2.5 Application Components</Typography>
        <List>
            <ListItem sx={listItemStyle}><Typography><strong>admin.py:</strong> Defines how models are displayed and managed within the Django admin interface.</Typography></ListItem>
            <ListItem sx={listItemStyle}><Typography><strong>apps.py:</strong> Configuration for the application module, including metadata and initialization.</Typography></ListItem>
            <ListItem sx={listItemStyle}><Typography><strong>models.py:</strong> Defines database models, including users, missions, invite codes, and Stripe-related fields. Relationships between entities are established here.</Typography></ListItem>
                <List>
                    <ListItem sx={listItemStyle2}> <Typography><strong>User Model:</strong> Represents users of the platform</Typography>    </ListItem>
                    <ListItem sx={listItemStyle2}> <Typography><strong>Mission Model:</strong> Represents user-defined missions or goals</Typography>    </ListItem>
                    <ListItem sx={listItemStyle2}> <Typography><strong>InviteCode Model:</strong> Handles referral and invitation codes</Typography>    </ListItem>
                </List>
            <ListItem sx={listItemStyle}><Typography><strong>views.py:</strong> Handles incoming HTTP requests and returns responses, implementing core business logic such as user profile management and API interactions.</Typography></ListItem>
                <List>
                    <ListItem sx={listItemStyle2}> <Typography><strong>User Profile View:</strong> Handles fetching and updating user profiles. Core functions include:</Typography>    </ListItem>
                        <List>
                            <ListItem sx={listItemStyle3}><Typography><strong>get_user_profile:</strong> Retrieves user details such as bio, connected social accounts, and subscription status.</Typography></ListItem>
                            <ListItem sx={listItemStyle3}><Typography><strong>update_user_profile:</strong> Updates user information, including social media connections and Stripe customer IDs.</Typography></ListItem>
                        </List>
                    <ListItem sx={listItemStyle2}> <Typography><strong>Mission Management View:</strong> Manages user-defined missions. Core functions include:</Typography>    </ListItem>
                        <List>
                            <ListItem sx={listItemStyle2}><Typography><strong>create_mission:</strong> Allows users to create and define new missions with descriptions and deadlines.</Typography></ListItem>
                            <ListItem sx={listItemStyle3}><Typography><strong>get_missions:</strong> Retrieves a list of missions associated with the requesting user.</Typography></ListItem>
                            <ListItem sx={listItemStyle3}><Typography><strong>update_mission:</strong> Allows users to modify mission details, including descriptions and statuses.</Typography></ListItem>
                        </List>
                    <ListItem sx={listItemStyle2}> <Typography><strong>Stripe Integration View:</strong> Handles payments and subscriptions using Stripe. Core functions include:</Typography>    </ListItem>
                        <List>
                            <ListItem sx={listItemStyle3}><Typography><strong>create_customer:</strong> Creates a new Stripe customer and links it to the user's profile.</Typography></ListItem>
                            <ListItem sx={listItemStyle3}><Typography><strong>handle_webhook:</strong> Processes webhook events from Stripe, such as subscription updates and payment confirmations.</Typography></ListItem>
                        </List>
                </List>
            <ListItem sx={listItemStyle}><Typography><strong>tests.py:</strong> Contains unit tests to verify the functionality and reliability of key application components.</Typography></ListItem>
        </List>

        <Typography variant="h6" style={{ marginTop: '20px' }}>2.6 Key Frontend Components</Typography>
        <List>
            <ListItem sx={listItemStyle}><Typography><strong>App.js:</strong> The main application component that manages routing and initializes key features such as authentication and navigation.</Typography></ListItem>
            <ListItem sx={listItemStyle}><Typography><strong>AppBar.js:</strong> Navigation bar component providing links to various sections of the application and responsive menu options.</Typography></ListItem>
            <ListItem sx={listItemStyle}><Typography><strong>Home.js:</strong> Dashboard component displaying key user statistics, account information, and quick links to important features.</Typography></ListItem>
            <ListItem sx={listItemStyle}><Typography><strong>Landing.js:</strong> Introduction page showcasing the platform's mission, core features, and onboarding content for new users.</Typography></ListItem>
            <ListItem sx={listItemStyle}><Typography><strong>Profile.js:</strong> Displays user profile information, including connected accounts and subscription details. Users can update their information directly from this component.</Typography></ListItem>
            <ListItem sx={listItemStyle}><Typography><strong>SignIn.js:</strong> Authentication form for users to log in using Firebase, supporting Google sign-in and email/password methods.</Typography></ListItem>
            <ListItem sx={listItemStyle}><Typography><strong>SignUp.js:</strong> Registration form that integrates with Firebase Authentication, allowing new users to create an account and verify their email addresses.</Typography></ListItem>
            <ListItem sx={listItemStyle}><Typography><strong>Socials.js:</strong> Connects to social media APIs (YouTube, Instagram, Twitter) to fetch and display user engagement metrics such as subscribers, views, and video counts. Users can toggle connections and refresh data.</Typography></ListItem>
            <ListItem sx={listItemStyle}><Typography><strong>Subscriptions.js:</strong> Manages Stripe-based subscriptions. Displays the current subscription status and dynamically renders payment options using Stripe integration.</Typography></ListItem>
            <ListItem sx={listItemStyle}><Typography><strong>Settings.js:</strong> Allows users to configure their preferences, manage connected services, and update personal information. Fetches and submits data through API calls.</Typography></ListItem>
            <ListItem sx={listItemStyle}><Typography><strong>ToggleColorMode.js:</strong> Implements theme switching between light and dark modes using Material-UI. The toggle button updates global styles through context.</Typography></ListItem>
            <ListItem sx={listItemStyle}><Typography><strong>EmailConfirmationWaiting.js:</strong> Displays a waiting screen after users initiate email confirmation, guiding them through the process.</Typography></ListItem>
            <ListItem sx={listItemStyle}><Typography><strong>PasswordReset.js:</strong> Handles the password reset flow, providing a form for users to reset their credentials.</Typography></ListItem>
            <ListItem sx={listItemStyle}><Typography><strong>MyData.js:</strong> Displays user-specific data collected from connected accounts, such as social media metrics and subscriptions.</Typography></ListItem>
            <ListItem sx={listItemStyle}><Typography><strong>FAQ.js:</strong> Static component that lists frequently asked questions about the platform and its services.</Typography></ListItem>
            <ListItem sx={listItemStyle}><Typography><strong>Features.js:</strong> Showcases the key features of the platform with detailed descriptions and interactive elements to guide users.</Typography></ListItem>
            <ListItem sx={listItemStyle}><Typography><strong>Footer.js:</strong> Application footer with site navigation links, contact information, and social media links.</Typography></ListItem>
            <ListItem sx={listItemStyle}><Typography><strong>Hero.js:</strong> Main hero section for landing pages, displaying key calls to action and a visually appealing introductory message.</Typography></ListItem>
            <ListItem sx={listItemStyle}><Typography><strong>PrivacyPolicy.js:</strong> Provides the privacy policy details of the platform, ensuring users are informed about data usage and rights.</Typography></ListItem>
            <ListItem sx={listItemStyle}><Typography><strong>ProfilePicture.js:</strong> Manages profile picture uploads and display, integrating with Firebase Storage for persistent storage.</Typography></ListItem>
            <ListItem sx={listItemStyle}><Typography><strong>MissionMaster.js:</strong> Onboarding and step-based setup process guiding users through initial configuration of their account.</Typography></ListItem>
            <ListItem sx={listItemStyle}><Typography><strong>LogoCollection.js:</strong> Displays logos of partners or companies associated with the platform, often used as part of marketing or promotional sections.</Typography></ListItem>
        </List>

        <Typography variant="h6" style={{ marginTop: '20px' }}>2.7 Deployment</Typography>
        <List>
          <ListItem sx={listItemStyle}><Typography><strong>WSGI Server:</strong> Gunicorn (3 workers configured)</Typography></ListItem>
          <ListItem sx={listItemStyle}><Typography><strong>Reverse Proxy:</strong> Nginx (serves static files and proxies API requests)</Typography></ListItem>
          <ListItem sx={listItemStyle}><Typography><strong>SSL:</strong> Let’s Encrypt for HTTPS</Typography></ListItem>
        </List>
        <Typography>
          The system is deployed on a Linux server, with automated static file collection and migration before deployment. Gunicorn serves the application, and Nginx handles incoming requests.
        </Typography>
      </section>

      <section id="section-3" style={{ marginTop: '40px' }}>
        <Typography variant="h5" gutterBottom>3. Components</Typography>

        <Typography variant="h6" style={{ marginTop: '20px' }}>3.1 Backend Components</Typography>
        <List>
            <ListItem sx={listItemStyle}><Typography><strong>Models:</strong> Define the structure of database tables and handle business data. Key models include:</Typography></ListItem>
            <List sx={{ paddingLeft: '32px' }}>
                <ListItem sx={listItemStyle}><Typography><strong>User:</strong> Extends Django’s built-in user model to support roles, Firebase UID, and social media integration.</Typography></ListItem>
                <ListItem sx={listItemStyle}><Typography><strong>Mission:</strong> Represents marketing campaigns, containing details about goals, rewards, creators, and statuses.</Typography></ListItem>
                <ListItem sx={listItemStyle}><Typography><strong>MissionAcceptance:</strong> Tracks when creators accept a mission, ensuring smooth collaboration.</Typography></ListItem>
                <ListItem sx={listItemStyle}><Typography><strong>InviteCode:</strong> Handles invite-based user registration and tracks the usage of invite codes.</Typography></ListItem>
            </List>
            <ListItem sx={listItemStyle}><Typography><strong>Views:</strong> Define logic for handling HTTP requests and providing JSON responses. They implement API endpoints for user profiles, mission management, payments, and authentication.</Typography></ListItem>
            <ListItem sx={listItemStyle}><Typography><strong>Middleware:</strong> Intercepts requests and responses. A custom Firebase middleware validates user sessions by verifying Firebase ID tokens.</Typography></ListItem>
            <ListItem sx={listItemStyle}><Typography><strong>Stripe Integration:</strong> Manages payments by interacting with Stripe APIs for subscriptions, payments, and webhook event handling.</Typography></ListItem>
            <ListItem sx={listItemStyle}><Typography><strong>Static Files Management:</strong> Collects and serves static files (CSS, JS) using Django’s <code>collectstatic</code> and Nginx.</Typography></ListItem>
            <ListItem sx={listItemStyle}><Typography><strong>Settings:</strong> Contains environment-specific configurations for databases, Firebase authentication, Stripe integration, and security settings.</Typography></ListItem>
        </List>

        <Typography variant="h6" style={{ marginTop: '20px' }}>3.2 Frontend Components</Typography>
        <List >
            <ListItem sx={listItemStyle}><Typography><strong>Main Application:</strong> (<code>App.js</code>) Manages routing, authentication, and overall app state, with core components like navigation and authentication guards.</Typography></ListItem>
            <ListItem sx={listItemStyle}><Typography><strong>Mission Management:</strong> (<code>MissionMaster.js</code>) A guided form for creating and updating missions through multiple steps.</Typography></ListItem>
            <ListItem sx={listItemStyle}><Typography><strong>Documentation:</strong> (<code>Documentation.js</code>) Displays comprehensive technical documentation in a structured and interactive format.</Typography></ListItem>
            <ListItem sx={listItemStyle}><Typography><strong>Service Components:</strong> (<code>service_components/</code>) Handle communication with external APIs (Firebase, Stripe) and abstract key logic for cleaner code.</Typography></ListItem>
        </List>
        </section>


      <section id="section-4" style={{ marginTop: '40px' }}>
        <Typography variant="h5" gutterBottom> 4. Deployment and Configuration </Typography>
        <List >
          <ListItem sx={listItemStyle}><Typography><strong>Gunicorn:</strong> The WSGI server running the Django application, with configuration set to 3 workers.</Typography></ListItem>
          <ListItem sx={listItemStyle}><Typography><strong>Nginx:</strong> Handles static file serving and proxies API requests to Gunicorn.</Typography></ListItem>
          <ListItem sx={listItemStyle}><Typography><strong>Environment Configuration:</strong> Separate settings for development and production.</Typography></ListItem>
          <ListItem sx={listItemStyle}><Typography><strong>SSL:</strong> Managed by Let’s Encrypt for secure HTTPS connections.</Typography></ListItem>
        </List>
        <Typography>
          Deployment Commands:
          <pre style={{ backgroundColor: '#f5f5f5', padding: '10px', overflow: 'auto' }}>
            npm run build<br/>
            python manage.py makemigrations --settings=abunda.settings_prod<br/>
            python manage.py migrate --settings=abunda.settings_prod<br/>
            sudo systemctl restart gunicorn<br/>
            sudo systemctl reload nginx
          </pre>
        </Typography>
      </section>

      <section id="section-5" style={{ marginTop: '40px' }}>
        <Typography variant="h5" gutterBottom> 5. Security Measures </Typography>
        <List >
          <ListItem sx={listItemStyle}><Typography><strong>Authentication:</strong> Middleware intercepts requests to validate user sessions through Firebase ID token verification.</Typography></ListItem>
          <ListItem sx={listItemStyle}><Typography><strong>SSL:</strong> Enforced for all traffic using Let’s Encrypt certificates.</Typography></ListItem>
          <ListItem sx={listItemStyle}><Typography><strong>Environment-Specific Settings:</strong> Development and production configurations are separated to minimize risks.</Typography></ListItem>
          <ListItem sx={listItemStyle}><Typography><strong>Security Headers:</strong> Nginx is configured with headers like <code>X-Frame-Options</code> and <code>Strict-Transport-Security</code> to protect against common vulnerabilities.</Typography></ListItem>
        </List>
      </section>

      <section id="section-6" style={{ marginTop: '40px' }}>
        <Typography variant="h5" gutterBottom> 6. Integration Overview </Typography>
        <List >
          <ListItem sx={listItemStyle}><Typography><strong>Firebase:</strong> Used for user authentication and session management.</Typography></ListItem>
          <ListItem sx={listItemStyle}><Typography><strong>Stripe:</strong> Handles payment processing and subscriptions.</Typography></ListItem>
          <ListItem sx={listItemStyle}><Typography><strong>Google Analytics:</strong> Integrated for tracking and analyzing user behavior.</Typography></ListItem>
        </List>
      </section>
    </div>
  );
};

export default Documentation;
