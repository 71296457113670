import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, Button, LinearProgress } from '@mui/material';
import { auth } from '../service_components/firebase'; // adjust the import path as needed

const apiUrl = process.env.REACT_APP_API_URL;

export default function AIScraper({ onData }) {
  const [loading, setLoading] = useState(false);
  const [progressMessage, setProgressMessage] = useState('');
  const [progressValue, setProgressValue] = useState(0);
  const [metrics, setMetrics] = useState(null);
  const [error, setError] = useState(null);
  const [idToken, setIdToken] = useState('');
  const [instaUsername, setInstaUsername] = useState('');

  // Get Firebase ID token
  const fetchToken = useCallback(async () => {
    if (auth.currentUser) {
      const token = await auth.currentUser.getIdToken(true);
      setIdToken(token);
    } else {
      console.error("User not logged in.");
    }
  }, []);
  useEffect(() => { fetchToken(); }, [fetchToken]);

  // Fetch user profile and extract Instagram username (assumed stored as instagram_channelId)
  const fetchProfileData = useCallback(async () => {
    if (!idToken) return;
    try {
      const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${idToken}` };
      const res = await fetch(`${apiUrl}/api/user-profile/`, { method: 'GET', headers });
      const profile = await res.json();
      if (res.ok) {
        setInstaUsername(profile.instagram_channelId || '');
      } else {
        console.error('Failed to fetch profile data:', profile.message);
      }
    } catch (err) {
      console.error('Error fetching profile data:', err);
    }
  }, [idToken]);
  useEffect(() => { if (idToken) fetchProfileData(); }, [idToken, fetchProfileData]);

  const fetchMetrics = async () => {
    if (!instaUsername) {
      setError("Instagram username not available.");
      return;
    }
    setLoading(true);
    setError(null);
    setProgressMessage('');
    setProgressValue(0);
    try {
      const response = await fetch(`${apiUrl}/api/instagram-metrics/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username: instaUsername }),
      });
      if (!response.ok) throw new Error('Failed to start metrics collection');
      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      let partialChunk = '';
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        partialChunk += decoder.decode(value);
        const lines = partialChunk.split('\n');
        for (let i = 0; i < lines.length - 1; i++) {
          const line = lines[i].trim();
          if (!line) continue;
          let data;
          try { data = JSON.parse(line); } catch { continue; }
          if (data.ExtractedProfileData) {
            setMetrics(data);
            setProgressValue(100);
            setProgressMessage('Done!');
            if (onData) onData(data);
            setTimeout(() => setLoading(false), 5000);
            return;
          }
          if (data.error) {
            setError(data.error);
            setLoading(false);
            return;
          }
          if (data.message) {
            setProgressMessage(data.message);
            const phaseMatch = data.message.match(/Phase\s+(\d+)/);
            if (phaseMatch) {
              const phase = Number(phaseMatch[1]);
              setProgressValue(Math.min(phase * 12.5, 100));
            }
          }
        }
        partialChunk = lines[lines.length - 1];
      }
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }}>
      {loading && (
        <Box sx={{ width: '100%', mt: 2 }}>
          <LinearProgress variant="determinate" value={progressValue} sx={{ height: 10, borderRadius: 2 }} />
          <Typography variant="body2" sx={{ mt: 1 }}>{progressMessage}</Typography>
        </Box>
      )}
      {error && <Typography color="error" sx={{ mt: 2 }}>{error}</Typography>}
      {metrics && (
        <Box sx={{ width: '100%', maxWidth: 800, mt: 2, mx: 'auto', bgcolor: '#f4f4f4', p: 2, borderRadius: 1, overflow: 'auto', maxHeight: 400 }}>
          <Typography variant="body2"><strong>Full JSON Response:</strong></Typography>
          <pre style={{ margin: 0, whiteSpace: 'pre-wrap' }}>{JSON.stringify(metrics, null, 2)}</pre>
        </Box>
      )}
      {!loading && !metrics && !error && (
        <Button variant="contained" onClick={fetchMetrics} sx={{ mt: 2 }}>Get Metrics</Button>
      )}
    </Box>
  );
}
