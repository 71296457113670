// File: MyLineChart.jsx
import React from 'react';
// Ensure you've installed the matching version: npm install @mui/x-charts@latest
import { LineChart } from '@mui/x-charts';

export default function MyLineChart() {
  // Example data: an array of objects
  const dataset = [
    { x: 1985, y: 100 },
    { x: 1986, y: 110 },
    { x: 1987, y:  95 },
    { x: 1988, y: 130 },
  ];

  return (
    <LineChart
      // The new "dataset" prop references your array of objects
      dataset={dataset}

      // xAxis uses dataKey to know which field from each object is the X value
      xAxis={[{ dataKey: 'x' }]}

      // series array references which field(s) are drawn on the Y axis
      series={[{ dataKey: 'y', label: 'Value' }]}

      width={500}
      height={300}
    />
  );
}
