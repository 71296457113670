import React from 'react';
import { Box, Typography, Grid, Avatar, ImageList, ImageListItem, Chip } from '@mui/material';
import { LineChart } from '@mui/x-charts';

const localImage = file =>
  process.env.NODE_ENV === 'development'
    ? `http://localhost:8000/media/${file}`
    : `/media/${file}`;

const trimText = (text, max = 100) =>
  text && text.length > max ? text.slice(0, max) + '...' : text;

export default function MetricsVisualizer({ data }) {
  if (!data?.ExtractedProfileData) return null;
  const { ExtractedProfileData: p, NicheAnalysis } = data;
  const n = NicheAnalysis?.InstagramCreatorProfileAnalysis || {};
  const {
    Username,
    FullName,
    Bio,
    Followers,
    Following,
    PostsCount,
    ProfilePicLocalFile,
    Top5PostImagesLocal = [],
    Last36PostsLikesAndComments = [],
    AverageLikesLast36Posts,
    AverageCommentsLast36Posts,
    EngagementRateLast36Posts,
    AveragePostingFrequencyDays,
  } = p;
  const { NicheAndSubnicheIdentification: ni, ContentFormatAnalysis: fa, AudienceDescription: aud, VisualAndAestheticStyle: aes } = n;
  
  return (
    <Box p={2}>
      <Typography variant="h6" gutterBottom>Profile Overview</Typography>
      <Grid container spacing={2}>
        {/* Profile Info */}
        <Grid item xs={12} md={6}>
          <Box display="flex" alignItems="center" gap={2}>
            <Avatar src={localImage(ProfilePicLocalFile)} sx={{ width: 80, height: 80 }} />
            <Box>
              <Typography variant="subtitle1" fontWeight="bold">{FullName} (@{Username})</Typography>
              <Typography variant="body2">{Bio}</Typography>
              <Typography variant="body2">Followers: <b>{Followers}</b></Typography>
              <Typography variant="body2">Following: <b>{Following}</b></Typography>
              <Typography variant="body2">Posts: <b>{PostsCount}</b></Typography>
            </Box>
          </Box>
          <Box mt={1} p={1} sx={{ bgcolor: 'grey.200', borderRadius: 1 }}>
            <Typography variant="subtitle2" fontWeight="bold">Profile Insights:</Typography>
            <Typography variant="body2">Average Likes: <b>{AverageLikesLast36Posts?.toFixed(1)}</b></Typography>
            <Typography variant="body2">Average Comments: <b>{AverageCommentsLast36Posts?.toFixed(1)}</b></Typography>
            <Typography variant="body2">Engagement Rate: <b>{EngagementRateLast36Posts}</b></Typography>
            <Typography variant="body2">Average Posting Frequency (days): <b>{AveragePostingFrequencyDays?.toFixed(1)}</b></Typography>
          </Box>
        </Grid>
        {/* Graph */}
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle1" gutterBottom>Likes &amp; Comments (36 Posts)</Typography>
          <LineChart
            height={300}
            xAxis={[{ dataKey: 'PostNumber', label: 'Post #' }]}
            series={[
              { dataKey: 'Likes', label: 'Likes', yAxisId: 'leftAxisId' },
              { dataKey: 'Comments', label: 'Comments', yAxisId: 'rightAxisId' }
            ]}
            yAxis={[
              { id: 'leftAxisId', label: 'Likes' },
              { id: 'rightAxisId', label: 'Comments' }
            ]}
            rightAxis="rightAxisId"
            dataset={Last36PostsLikesAndComments}
          />
        </Grid>
      </Grid>
      {/* Top 5 Images */}
      <Box mt={3}>
        <Typography variant="h6" gutterBottom>Top 5 Post Images</Typography>
        <ImageList cols={5} gap={8}>
          {Top5PostImagesLocal.map((item, i) => (
            <ImageListItem key={i}>
              <img src={localImage(item.LocalFile)} alt={item.Description} loading="lazy" style={{ width: '100%', display: 'block' }} />
              <Box mt={1}>
                <Typography variant="body2" fontWeight="bold">Likes: {item.Likes}</Typography>
                <Typography variant="caption">{trimText(item.Description)}</Typography>
              </Box>
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {/* Niche Analysis */}
      <Box mt={3}>
        <Typography variant="h6" gutterBottom>Niche Analysis</Typography>
        <Typography variant="body1" paragraph>{NicheAnalysis?.ProfileSummary}</Typography>
        {ni && (
          <Box mb={1}>
            <Typography variant="body2" fontWeight="bold">Primary Niche:</Typography>
            <Chip label={ni.PrimaryNiche} color="primary" sx={{ mr: 1, mt: 0.5 }} />
          </Box>
        )}
        {ni && ni.Subniches && (
          <Box mb={1}>
            <Typography variant="body2" fontWeight="bold">Subniches:</Typography>
            {ni.Subniches.map((tag, idx) => (
              <Chip key={idx} label={tag} variant="outlined" sx={{ mr: 1, mb: 1 }} />
            ))}
          </Box>
        )}
        {fa && (
          <Box mb={1}>
            <Typography variant="body2" fontWeight="bold">Content Format Analysis:</Typography>
            {fa.MostFrequentContentTypes && Object.entries(fa.MostFrequentContentTypes).map(([k, v]) => (
              <Typography key={k} variant="body2">{k}: {v}</Typography>
            ))}
            <Typography variant="body2" paragraph>Popular Recurring Formats: {fa.PopularRecurringFormats?.join(', ')}</Typography>
          </Box>
        )}
        {aud && (
          <Box mb={1}>
            <Typography variant="body2" fontWeight="bold">Audience Description:</Typography>
            <Typography variant="body2" paragraph>{aud.PossibleAudience}</Typography>
          </Box>
        )}
        {aes && (
          <Box mb={1}>
            <Typography variant="body2" fontWeight="bold">Visual &amp; Aesthetic Style:</Typography>
            <Typography variant="body2">Core Aesthetic: {aes.CoreAesthetic}</Typography>
            <Typography variant="body2">Color Schemes: {aes.ColorSchemes}</Typography>
            <Typography variant="body2" paragraph>Editing Style: {aes.EditingStyle}</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}
