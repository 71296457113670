import React, { useEffect, useState, createContext, useContext, } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Typography, Container } from '@mui/material';
import { auth } from './service_components/firebase';
import { onAuthStateChanged, signOut, reload } from 'firebase/auth';
import ReactGA from 'react-ga4';
import SignUp from './SignUp';
import Documentation from './components/Documentation';
import ChartTest from './components/ChartTest';
import SignIn from './SignIn';
import Profile from './Profile';
import CreatorPage from './components/CreatorPage';
import BrandPage from './components/BrandPage';
import PasswordReset from './components/PasswordReset';
import PrivacyPolicy from './components/PrivacyPolicy';
import EmailConfirmationWaiting from './components/EmailConfirmationWaiting';
import Home from './Home';
import Master from './components/MissionMaster';
import LandingPage from './Landing';
import AppAppBar from './AppBar';
import OAuth2Callback from './service_components/OAuth2Callback';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const ThemeContext = createContext();
const TRACKING_ID = "G-NLWX0S24LJ";
ReactGA.initialize(TRACKING_ID);

function App() {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [mode, setMode] = useState('light');
  const [profilePicUrl, setProfilePicUrl] = useState('');
  
  const theme = createTheme({ palette: { mode: mode }});
  const toggleColorMode = () => { setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));  };

  useEffect(() => { ReactGA.send({ hitType: "pageview", page: window.location.pathname });  }, []);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) { await reload(user); }
      setCurrentUser(user);
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      alert("Signed out successfully");
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  if (loading) { return ( <Container sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}> <Typography variant="h5">Loading...</Typography> </Container> ); }

  function RequireEmailVerification({ children }) {
    if (currentUser && !currentUser.emailVerified) {
      console.log("Redirecting to email confirmation because email is not verified");
      return <Navigate replace to="/email-confirmation-waiting" />;
    } return children;  }

  return (
    <ThemeContext.Provider value={{ mode, toggleColorMode }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
            <AppAppBar mode={mode} toggleColorMode={toggleColorMode} currentUser={currentUser} handleSignOut={handleSignOut} profilePicUrl={profilePicUrl}/>
            <Routes>
              <Route path="/oauth2callback" element={<OAuth2Callback />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/creator/:creatorId" element={<CreatorPage />} />
              <Route path="/brand/:brandId" element={<BrandPage />} />
              <Route path="/" element={<LandingPage mode={mode} toggleColorMode={toggleColorMode}/>} />
              <Route path="/home" element={ currentUser ? ( <RequireEmailVerification> <Home /> </RequireEmailVerification> ) : ( <Navigate replace to="/" /> )} />
              <Route path="/mission" element={ currentUser ? ( <RequireEmailVerification> <Master /> </RequireEmailVerification> ) : ( <Navigate replace to="/" /> )} />
              <Route path="/mission/:missionId" element={ currentUser ? ( <RequireEmailVerification> <Master /> </RequireEmailVerification> ) : ( <Navigate replace to="/" /> )} />
              <Route path="/signup" element={!currentUser ? <SignUp /> : <Navigate replace to="/home" />} />
              <Route path="/signin" element={!currentUser ? <SignIn /> : <Navigate replace to="/home" />} />
              <Route path="/profile" element={ currentUser ? (<RequireEmailVerification> <Profile onProfilePictureUpdate={setProfilePicUrl} /> </RequireEmailVerification>) : ( <Navigate replace to="/" /> ) } />
              <Route path="/password-reset" element={<PasswordReset />} />
              <Route path="/email-confirmation-waiting" element={<EmailConfirmationWaiting />} />
              <Route path="/docs" element={currentUser ? <Documentation /> : <Navigate replace to="/signin" />} />
              <Route path="/chart-test" element={<ChartTest />} />
            </Routes>
        </Router>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
}

export function useThemeMode() { return useContext(ThemeContext); }
export default App;